import { ButtonUnstyled } from '@mui/base';
import { Container } from '@mui/material';

import SearchBox from '../../layouts/main-layout/search-box';

import styles from './mobile-search-area.module.scss';

export default function MobileSearchArea() {
  return (
    <aside className={styles.mobileSearchArea}>
      <Container>
        <section className={styles.search}>
          <SearchBox large />
          <ButtonUnstyled type="submit">Buscar</ButtonUnstyled>
        </section>
      </Container>
    </aside>
  );
}
