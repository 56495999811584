import { Container } from '@mui/material';
import Head from 'next/head';

import MiddleHighlights from '../components/home/middle-highlights';
import MobileSearchArea from '../components/home/mobile-search-area';
import Posts from '../components/home/post-summary/posts';
import TopArea from '../components/home/top-area';
import MainLayout from '../components/layouts/main-layout';

import type { NextPage } from 'next';

const Home: NextPage = () => {
  return (
    <MainLayout>
      <Head>
        <title>W2W - Home</title>
      </Head>

      <MobileSearchArea />

      <TopArea />

      <Container maxWidth="lg" sx={{ mt: 6 }}>
        <main>
          <MiddleHighlights />

          <Posts />
        </main>
      </Container>
    </MainLayout>
  );
};

// noinspection JSUnusedGlobalSymbols
export default Home;
