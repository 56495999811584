import Image from 'next/image';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

import { IPost } from '../../../utils/api';
import { useRealUrl } from '../../../utils/images';

import styles from './middle-highlights.module.scss';

type Props = PropsWithChildren<{ post: IPost }>;

export default function HighlightItem({ post }: Props) {
  const realUrl = useRealUrl(post.coverImage);

  return (
    <article className={styles.item}>
      {!realUrl ? (
        <span />
      ) : (
        <Link href={`/posts/${post.id}`}>
          <Image src={realUrl} width={339} height={226} alt={post.title} className="img" />
        </Link>
      )}
      <h1>
        <Link href={`/posts/${post.id}`}>{post.title}</Link>
      </h1>
    </article>
  );
}
