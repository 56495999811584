import useSWR from 'swr';

import { IPost } from '../../../utils/api';

import HighlightItem from './highlight-item';

import styles from './middle-highlights.module.scss';

export default function MiddleHighlights() {
  const { data: highlights } = useSWR<IPost[]>('/api/posts/highlights/middle_area');

  return (
    <div className={styles.list}>
      {highlights?.map((p) => (
        <HighlightItem key={p.id} post={p} />
      ))}
    </div>
  );
}
